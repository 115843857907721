<template>
  <div class="date-grid" v-class-mod:date-grid="select">
    <div v-for="week in getMonth(viewDate)" class="date-grid__week" @click="selectWeek(week)" :key="+week?.[0]">
      <span
        v-for="day in week"
        :tabindex="isDisabled(day) ? -1 : 0"
        class="date-grid__day"
        @click="selectDay(day)"
        @mouseenter="suggestDay(day)"
        @mouseleave="suggestDay(day, false)"
        @keydown.enter="selectDay(day)"
        :key="day.format(DateFormat.DAY)"
        ref="dayElem"
        v-class-mod:date-grid__day="{
          isToday: isSameDate(day, today),
          isDisabled: isDisabled(day),
          isCurrentDurationEnd: isSameDate(day, currentEndDate),
          isCurrentDurationStart: isSameDate(day, currentStartDate),
          isSuggestedDurationEnd: isSameDate(day, suggestedEndDate),
          isSuggestedDurationStart: isSameDate(day, suggestedStartDate),
          isDifferenMonth: !day.isSame(viewDate, 'month'),
          isCurrentDuration: isDuration(day, currentStartDate, currentEndDate),
          isSuggestedDuration: isDuration(day, suggestedStartDate, suggestedEndDate),
          isCurrentDay: isSameDate(day, currentStartDate) || isSameDate(day, currentEndDate),
        }"
      >
        {{ day.format(DateFormat.DD) }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";

import { DateFormat, DateTime, DurationObject, getDurationObject, getMonth, getTime } from "@horizon56/time";
import { isHtmlElem } from "@horizon56/utils";

import { Select } from "../date-picker.vue";

const props = defineProps<{
  select: Select;
  viewDate: DateTime;
  selectedDate?: DateTime | DurationObject;
  min?: DateTime;
  max?: DateTime;
}>();

const emit = defineEmits<{
  (e: "update:week", arg: DurationObject): void;
  (e: "update:range", arg: DurationObject): void;
  (e: "update:date", a: DateTime): void;
}>();

const suggestedEndDate = ref<DateTime>();
const suggestedStartDate = ref<DateTime>();

const today = computed(() => getTime(true));

const currentStartDate = ref<DateTime>();
const currentEndDate = ref<DateTime>();

const dayElem = ref<HTMLElement[]>();

const isDuration = (day: DateTime, start?: DateTime, end?: DateTime) =>
  !!start && !!end && +day >= +start.startOf("date") && +day <= +end.endOf("date");

const isSameDate = (day: DateTime, match?: DateTime | null) => match && day.isSame(match, "date");

const isDisabled = (day: DateTime) => {
  if (props.min && day.isBefore(props.min, "date")) {
    return true;
  }
  if (props.max && day.isAfter(props.max, "date")) {
    return true;
  }

  return false;
};

const selectWeek = (week: DateTime[]) => {
  if (props.select === "week") {
    emit("update:week", getDurationObject(week));
  }
};

const selectDay = (day: DateTime) => {
  if (isDisabled(day)) {
    return false;
  }
  if (props.select === "date") {
    emit("update:date", day);
  } else if (props.select === "range") {
    if (!!currentStartDate.value && !!currentEndDate.value) {
      currentStartDate.value = day;
      currentEndDate.value = undefined;
    } else if (!!currentStartDate.value && !currentEndDate.value) {
      emit("update:range", getDurationObject([currentStartDate.value, day]));
    } else {
      currentStartDate.value = day;
    }
  }
};

const suggestDay = (day: DateTime, add = true) => {
  if (props.select !== "range") {
    return;
  }
  if (!add) {
    suggestedStartDate.value = undefined;
    suggestedEndDate.value = undefined;
    return;
  }

  if (!!currentStartDate.value && !currentEndDate.value) {
    const duration = getDurationObject([currentStartDate.value, day]);
    suggestedStartDate.value = duration.from;
    suggestedEndDate.value = duration.to;
  }
};

const setFocus = () => {
  if (Array.isArray(dayElem.value)) {
    const selected = dayElem.value.find((e) => e.classList.contains("date-grid__day--isCurrentDay"));
    if (isHtmlElem(selected)) {
      selected.focus();
    }
  }
};

watch(
  () => props.selectedDate,
  () => {
    const date = props.selectedDate;
    if (!date) {
      currentStartDate.value = undefined;
      currentEndDate.value = undefined;
      return;
    }
    if ("from" in date) {
      currentStartDate.value = date?.from;
      currentEndDate.value = date?.to;
    } else if ("set" in date) {
      currentStartDate.value = date;
    }
  },
  { immediate: true, deep: true },
);

defineExpose({ setFocus });
</script>

<style lang="scss" scoped>
.date-grid {
  $block: &;
  $space: calc((var(--width) - (var(--app-button-height-medium) * 7)) / 6);
  $offset: calc(var(--app-button-height-medium) / 2);

  display: flex;
  flex-flow: column;
  &__week {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 10px;
    border-radius: var(--app-button-height-medium);
    transition: background-color var(--app-transition);
  }
  &__day {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    border-radius: 50%;
    font-size: var(--app-font-size-base);
    width: var(--app-button-height-medium);
    height: var(--app-button-height-medium);
    transition: color var(--app-transition);
    z-index: 1;
    &:before,
    &:after {
      z-index: -2;
      position: absolute;
      @include position-corner;
      transition:
        background-color var(--app-transition),
        border-color var(--app-transition);
    }
    &:after {
      content: "";
      z-index: -1;
      border-radius: var(--app-button-height-medium);
    }
    @include focus-outline;
    &--isDisabled {
      opacity: 0.3;
      pointer-events: none;
    }
    &--isDifferenMonth {
      color: var(--black-50);
    }
    &--isToday {
      &:after {
        border: 3px solid var(--green-500);
      }
      @at-root #{$block}--week #{$block}__day--isCurrentDuration:after {
        border-color: var(--menu-bg);
      }
    }
  }
  &--date {
    #{$block}__day {
      &--isCurrentDay {
        color: var(--static-white);
        &:after {
          background: var(--green-500);
        }
      }
      &:hover:not(#{&}--isCurrentDay):not(#{&}--isDisabled) {
        color: var(--black-90);
        &:after {
          background: var(--content-hover);
        }
      }
    }
  }
  &--week {
    #{$block}__week:hover {
      cursor: pointer;
      background-color: var(--content-hover);
      #{$block}__day {
        color: var(--black-90);
      }
    }
    #{$block}__day {
      &--isCurrentDuration:before {
        content: "";
        background: var(--green-500);
      }
      &--isCurrentDurationStart:before {
        border-top-left-radius: var(--app-button-height-medium);
        border-bottom-left-radius: var(--app-button-height-medium);
      }
      &--isCurrentDurationEnd:before {
        border-top-right-radius: var(--app-button-height-medium);
        border-bottom-right-radius: var(--app-button-height-medium);
      }
      &--isCurrentDuration:has(+ #{$block}__day--isCurrentDuration):before {
        right: calc(#{$space} * -1);
      }
    }
  }
  &--range #{&}__day {
    &:not(#{$block}__day--isCurrentDurationStart):hover {
      color: var(--black-90);
      &:after {
        background-color: var(--black-20);
      }
    }
    &--isCurrentDuration,
    &--isSuggestedDuration {
      &:before {
        content: "";
      }
    }
    &--isCurrentDuration:before {
      background-color: var(--green-200);
    }
    &--isSuggestedDurationEnd,
    &--isSuggestedDuration {
      &:before {
        background-color: var(--black-10);
      }
    }
    &--isSuggestedDurationStart,
    &--isCurrentDurationStart {
      &:before {
        border-top-left-radius: var(--app-button-height-medium);
        border-bottom-left-radius: var(--app-button-height-medium);
      }
      &:after {
        background-color: var(--green-500);
      }
    }
    &--isSuggestedDurationEnd,
    &--isCurrentDurationEnd {
      &:before {
        border-top-right-radius: var(--app-button-height-medium);
        border-bottom-right-radius: var(--app-button-height-medium);
      }
    }
    &--isSuggestedDurationEnd:before {
      left: 0;
      border-radius: 0;
      border-top-right-radius: var(--app-button-height-medium);
      border-bottom-right-radius: var(--app-button-height-medium);
    }
    &--isCurrentDurationEnd:after {
      background-color: var(--green-500);
    }
    &--isSuggestedDurationEnd:before {
      background-color: var(--black-10);
    }
    &--isSuggestedDuration:has(+ #{$block}__day--isSuggestedDuration),
    &--isCurrentDuration:has(+ #{$block}__day--isCurrentDuration):not(#{$block}__day--isSuggestedDuration) {
      &:before {
        right: calc(#{$space} * -1);
      }
    }
  }
}
</style>
